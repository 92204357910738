body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif; /* You can change this to your preferred font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}