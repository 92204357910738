/* Add these new styles at the top of your Hero.css file */
body {
    margin: 0;
    padding: 0;
    font-family: 'Bebas Neue', sans-serif;
}

.App {
    min-height: 180vh; /* Reduced from 200vh to 180vh */
    background-color: black;
    color: white;
}

/* Remove or comment out this transition */
/* .App {
    transition: background-color 0.3s ease, color 0.3s ease;
} */

.header {
    position: fixed; /* Change from absolute to fixed */
    top: 20px;
    left: 20px;
    right: 20px; /* Add this to extend the header to the right */
    z-index: 10;
    display: flex; /* Add this to align items horizontally */
    justify-content: space-between; /* Add this to push items to the edges */
    align-items: center; /* Add this to vertically center items */
    transition: color 0.3s ease;
}

.second-page-header {
    color: black;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 40px; /* Adjust this value as needed */
    width: auto;
}

.logo-text {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 24px;
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.3s ease, color 0.3s ease;
}

.second-page-header .logo-text {
    color: black;
}

.logo-text.visible {
    opacity: 1;
}

.about-section {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.about-section:hover {
    opacity: 0.8;
}

.contact-section {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 24px;
    cursor: pointer;
    transition: opacity 0.3s ease, color 0.3s ease;
}

.second-page-header .contact-section {
    color: black;
}

.contact-section:hover {
    opacity: 0.8;
    color: #8c77e7; /* Added this line to change color on hover */
}

/* Keep your existing styles below */
.hero {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Changed from space-between to center */
    padding: 0 40px;
    box-sizing: border-box;
}

.hero-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.hero-content {
    max-width: 1200px;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0 5%;
    text-align: left; /* Changed from center to left */
}

.hero-content h1 {
    font-family: 'Anton', sans-serif;
    font-weight: 400;
    font-size: 105px; /* Increased from 70px to 105px (50% increase) */
    line-height: 0.9;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    max-width: 90%;
    margin: 0; /* Changed from 0 auto to 0 */
    text-align: left; /* Changed from center to left */
    white-space: pre-line;
}

.animated-line-container {
    position: absolute;
    bottom: 15%; /* Changed from 5% to 15% to move it higher */
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 5%; /* Reduced to 50% of original size (was 10%) */
    overflow: hidden;
}

.animated-line {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: white;
    transition: height 0.02s linear, top 0.02s linear; /* Smooth animation for both height and top */
}

.second-page {
    min-height: 80vh; /* Reduced from 100vh to 80vh */
    display: flex;
    justify-content: flex-start; /* Changed from center to flex-start */
    align-items: center;
    padding: 0 40px; /* Removed top and bottom padding */
}

.squares-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1600px;
    width: calc(100% - 80px);
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
    transform: translateY(-60px);
}

.square {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    aspect-ratio: 1 / 1;
    transition: background-color 0.3s ease;
    background-color: rgba(235, 235, 235, 1); /* Default light background */
}

.square::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(20, 20, 20, 0.1); /* Slight dark overlay */
    z-index: 1;
    transition: opacity 0.3s ease;
}

.square:hover::before {
    opacity: 0;
}

.square-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.square-content p {
    color: black;
    font-weight: bold;
    font-size: 24px;
    transition: color 0.5s ease;
}

.animated-text {
    transition: transform 0.5s ease, opacity 0.5s ease, color 0.5s ease;
}

.animated-text.hovered {
    animation: swooshOut 0.5s ease forwards, swooshInWhite 0.5s ease 0.5s forwards;
}

@keyframes swooshOut {
    0% {
        opacity: 1;
        transform: translateX(0);
        color: black;
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
        color: black;
    }
}

@keyframes swooshInWhite {
    0% {
        opacity: 0;
        transform: translateX(100%);
        color: white;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
        color: white;
    }
}

.hover-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 0.3s ease, transform 0.5s ease;
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
}

.color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    mix-blend-mode: multiply;
}

.square:hover .hover-image,
.square:active .hover-image,
.square:focus .hover-image {
    opacity: 1;
    transform: scale(1);
}

@media (max-width: 1200px) { /* Adjusted breakpoint */
    .squares-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) { /* Adjusted breakpoint */
    .squares-container {
        grid-template-columns: 1fr;
        gap: 20px;
        width: 100%;
        margin: 0; /* Set margin to 0 on mobile screens */
        padding: 0; /* Remove padding on mobile screens */
        transform: none; /* Remove the vertical shift on mobile */
    }
    
    .square {
        min-height: 240px; /* Increased by 20% from 200px */
        aspect-ratio: 1 / 1; /* Maintain square shape on mobile */
        width: 100%; /* Full width on mobile */
    }
}

.transition-block {
    position: fixed; /* Change from absolute to fixed */
    bottom: 0;
    height: 40px; /* Increased from 20px to 40px */
    transition: opacity 1s ease, background-color 1s ease; /* Changed both to 1s */
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 60px; /* Increased from 40px to 60px (50% increase) */
    }
    
    .second-page {
        margin-bottom: 60px; /* Increase gap between last box and typing animation */
    }
    
    .typing-animation-container {
        margin-bottom: 10px; /* Decrease gap between typing animation and Let's talk section */
    }
    
    .third-page-content {
        margin-top: 20px; /* Adjust top margin to bring Let's talk section closer to typing animation */
    }
}

.third-page {
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    color: inherit;
    padding: 0; /* Remove padding */
    position: relative; /* Add this */
}

.third-page-line-container {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px; /* Set to desired max-width */
    height: 2px;
    overflow: hidden;
}

.third-page-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: black;
    transition: width 0.02s linear, left 0.02s linear;
}

.third-page-content {
    margin-top: 70px; /* Add some margin to push content below the line */
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 40px 0; /* Add top padding to push content below the line */
}

.lets-talk-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lets-talk-text {
    font-size: 18px;
    margin-bottom: 10px;
}

.email-text {
    font-size: 72px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
}

.back-to-top {
    font-size: 18px;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
    align-self: flex-start;
    color: #000; /* Default color */
}

.back-to-top:hover {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M16 4 L16 28 M8 12 L16 4 L24 12' stroke='%238c77e7' stroke-width='2' fill='none'/%3E%3C/svg%3E") 16 0, auto;
    transform: translateY(-5px);
    color: #8c77e7; /* Change text color on hover to match the new arrow color */
}

@media (max-width: 768px) {
    .third-page-content {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0; /* Remove top margin for mobile screens */
        padding-top: 20px; /* Reduce top padding to 20px for mobile screens */
    }

    .back-to-top {
        margin-top: 20px;
        align-self: flex-end;
    }

    .email-text {
        font-size: 48px;
    }
}

.footer {
    background-color: transparent;
    color: #808080;
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer p {
    margin: 0;
}

.typing-animation-container {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.typing-text {
    font-size: 24px;
    font-weight: bold;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #E8A3A1; /* Changed cursor color to #E8A3A1 */
    animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #E8A3A1 } /* Changed cursor color to #E8A3A1 */
}

/* Add or modify these media queries at the end of your file */
@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 60px;
    }

    .squares-container {
        grid-template-columns: 1fr;
        gap: 20px;
        width: 100%;
        margin: 0;
        padding: 0;
        transform: none;
    }
    
    .square {
        min-height: 240px;
        aspect-ratio: 1 / 1;
        width: 100%;
    }
}

@media (max-width: 410px) {
    .App {
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .hero {
        padding: 0 20px; /* Reduce padding for very small screens */
    }

    .hero-content h1 {
        font-size: 40px; /* Further reduce font size for very small screens */
        max-width: 100%; /* Ensure text doesn't overflow */
    }

    .squares-container {
        width: 100%;
        padding: 0 20px; /* Add some padding to prevent touching the edges */
    }

    .square {
        min-height: 200px; /* Slightly reduce the minimum height */
    }

    .third-page-content {
        padding: 50px 20px 0; /* Reduce padding for very small screens */
    }

    .email-text {
        font-size: 36px; /* Reduce font size for very small screens */
    }
}